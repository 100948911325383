<template>
  <div class="h5kmCenter">
    <van-nav-bar
      title="七周年用户中心"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <div class="searchBox">
      <span :class="['iconBtn',{'beingClick':beingClick}]">
        <i @click="beingClick=!beingClick;" :style="{transform:beingClick?'rotate(90deg)':'rotate(-360deg)',color:beingClick?'#2e62cd':''}" class="el-icon-s-unfold"></i>
      </span>
      <van-search
        @click="beingClick=false;"
        :class="['vanSearch']"
        v-model="formInline.keyWord"
        background="#2c80c5"
        shape="round"
        @input="search"
        placeholder="请输入 姓名/手机号/备注/门市"
      />
    </div>
    <!-- <van-pagination v-model="pages.page" @change="changePage" mode="simple"  :total-items="pages.total*1" :items-per-page="pages.pageNum*1" /> -->
    <div class="cellDiv" style="background: #4b95d3;color:#fff;">
      <span>宝宝姓名</span>
      <span>手机号</span>
      <span>状态</span>
      <span>备注</span>
    </div>
    <van-pull-refresh class="cellBox" v-model="tableLoading" @refresh="search">
      <div
        class="cellDiv"
        v-for="item in tableData"
        :key="item._id"
        @click="editConsumer(item)"
      >
        <span>{{ item.name }}</span>
        <span>{{ item.phone }}</span>
        <span>
          <van-tag class="vanTag" type="primary" v-if="item.status == 1">未处理</van-tag>
          <van-tag class="vanTag" type="warning" v-else-if="item.status == 2">处理中</van-tag>
          <van-tag class="vanTag" type="success" v-else-if="item.status == 3">已完成</van-tag>
          <span v-else ></span>
        </span>
        <span>{{ item.comment }}</span>
      </div>
      <div class="noneData" v-if="tableData.length == 0" >
        <i class="el-icon-coffee"></i>
        暂无数据
      </div>
    </van-pull-refresh>
     <el-pagination
        background
        small
        layout="total,prev, pager, next"
        :current-page="pages.page * 1"
        :total="pages.total"
        :page-size="pages.pageNum * 1"
        style="margin:10px;text-align:center;"
        @size-change="changePageSize"
        @current-change="changePage"
      >
      </el-pagination>
    <!-- 遮罩层 -->
    <van-overlay :show="dialogVisible" @click="dialogVisible = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="main">
            <div class="wrapHeader">
              <span class="title">{{isEdit?'正在编辑':'查看详情'}}</span>
              <van-button type="primary" @click="wantEdit">{{isEdit?'取消编辑':'编辑'}}</van-button>
            </div>
            <!-- 编辑 -->
            <van-cell-group v-if="isEdit" >
              <van-field v-model="form.date" label="编辑时间" :disabled="true" :center="true" size="large" />
              <van-field v-model="form.name" label="宝宝姓名" :center="true" size="large" placeholder="请输入" />
              <van-field v-model="form.phone" label="手机号"  :center="true" size="large" placeholder="请输入" />
              <van-field v-model="form.principal" label="门市"  :center="true" size="large" placeholder="请输入" />
              <div class="van-cell van-cell--center" style="display:flex;">
                <span style="font-size:16px;color:#323233;width: 4.2em;" >状态</span>
                <van-radio-group v-model="form.status" direction="horizontal">
                  <van-radio name="1">未处理</van-radio>
                  <van-radio name="2">处理中</van-radio>
                  <van-radio name="3">已完成</van-radio>
                </van-radio-group>
              </div>
              <div class="van-cell van-cell--center">
                <span style="font-size:16px;color:#323233;width: 4.2em;" >礼品</span>
                <van-radio-group v-model="form.type" direction="horizontal">
                  <van-radio @click="giftTypeChange" class="radioSingle" :name="'gift1'">户外帐篷</van-radio>
                  <van-radio @click="giftTypeChange" class="radioSingle" :name="'gift2'">拉杆箱</van-radio>
                  <van-radio @click="giftTypeChange" class="radioSingle" :name="'gift3'">溜娃神器</van-radio>
                </van-radio-group>
              </div>
              <van-field
                v-model="form.comment"
                rows="1"
                autosize
                label="备注"
                type="textarea"
                placeholder="请输入"
              />
            </van-cell-group>
            <!-- 展示详情 -->
            <van-cell-group v-else >
              <van-cell
                :clickable="true"
                size="large"
                title="添加/修改时间"
                :value="form.date"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="宝宝姓名"
                :value="form.name"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="手机号"
                :value="form.phone"
              />
              <van-cell
                :clickable="true"
                size="large"
                title="门市"
                :value="form.principal"
              />
              <van-cell :clickable="true" size="large" title="状态">{{
                form.status == 1
                  ? "未处理"
                  : form.status == 2
                  ? "处理中"
                  : form.status == 3
                  ? "已完成"
                  : ""
              }}</van-cell>
              <van-cell :clickable="true" size="large" title="礼品">{{
                form.type == "gift1"
                  ? "户外帐篷"
                  : form.type == "gift2"
                  ? "拉杆箱"
                  : form.type == "gift3"
                  ? "溜娃神器"
                  : ""
              }}</van-cell>
              <van-cell
                :clickable="true"
                size="large"
                title="备注"
                :value="form.comment"
              />
            </van-cell-group>
          </div>
          <div class="btnDiv" v-if="isEdit" >
            <van-button class="btn" block @click="dialogVisible = false" plain type="primary" color="#337d56" style="border:none;border-radius:0;border-top:1px solid #2c80c5;">取消</van-button>
            <van-button class="btn" type="primary" block @click="onEdit" color="#2c80c5" style="border: none; border-radius: 0"
            >保存</van-button>
          </div>
          <div class="btnDiv" v-else>
            <van-button class="btn" type="primary" block @click="dialogVisible = false" color="#2c80c5" style="border: none; border-radius: 0"
            >关闭</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "H5ConsumerList",
  components: {},
  data() {
    return {
      formInline: {
        name: "",
        phone: "",
        date: "",
        keyWord: "",
        status: "",
      },
      pages: {
        page: 1,
        pageNum: 25,
        total: 0,
      },
      tableData: [],
      finished: false,
      dialogVisible: false,
      // 添加导入数据
      form: {
        _id: "",
        name: "",
        phone: "",
        principal: "",
        type: "",
        comment: "",
        status: "",
      },
      // 暂存被编辑数据
      oldForm:{},
      isEdit: false, // 判断是否为编辑
      options: [
        {
          value: "1",
          label: "未处理",
        },
        {
          value: "2",
          label: "处理中",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      tableLoading: false,
      btnLoading: false,
      beingClick: false,
    };
  },
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    search() {
      // 户外帐篷-1 拉杆箱-2 溜娃神器-3
      this.pages.page = 1;
      this.getData();
    },
    changePage(val) {
      this.pages.page = val * 1;
      this.getData();
    },
    // form type radio 选择渲染
    giftTypeChange() {
      // this.$set(this.form,'type',val);
      // 不强制更新 会导致数据变化视图没有变化
      this.$forceUpdate();
    },
    // 获取数据
    async getData() {
      this.tableLoading = true;
      await axios
        .get(
          this.$baseUrl +
            "/consumerList?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&status=" +
            this.formInline.status +
            "&date=" +
            this.formInline.date +
            "&keyWord=" +
            this.formInline.keyWord
        )
        .then((res) => {
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
          this.tableData = res.data.list;
          this.pages = res.data.pages;
          this.pages.page = res.data.pages.page * 1;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    //导出excel
    exportExcel() {
      axios
        .get(
          this.$baseUrl +
            "/consumerList/exportExcel?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&status=" +
            this.formInline.status +
            "&date=" +
            this.formInline.date +
            "&keyWord=" +
            this.formInline.keyWord
        )
        .then((res) => {
          console.log("导出");
          window.open(res.data.url);
        });
    },
    // 点击导入
    openAdd() {
      this.form = {
        _id: "",
        name: "",
        phone: "",
        principal: "",
        type: "",
        comment: "",
        status: "",
      };
      this.dialogVisible = true;
      this.isEdit = false;
    },
    // 点击编辑按钮
    wantEdit() {
      this.isEdit = !this.isEdit;
      if(!this.isEdit) {
        this.form =  {...this.oldForm};
        this.$forceUpdate();
        console.log(this.oldForm,'old',this.form)
      }
    },
    // 点击列表，展示已有用户信息
    editConsumer(scope) {
      this.isEdit = false;
      this.dialogVisible = true;
      let { name, phone, principal, comment, status, _id, date } = scope;
      this.form = { name, phone, principal, comment, status, _id, date };
      this.form.type =
        scope.gift1.trim() != ""
          ? "gift1"
          : scope.gift2.trim() != ""
          ? "gift2"
          : scope.gift3.trim() != ""
          ? "gift3"
          : "";
      // 暂存数据
      this.oldForm = {...this.form};
      console.log(this.form);
    },
    // 确认添加
    onSubmit() {
      this.btnLoading = true;
      let param = { ...this.form };
      param.gift1 = "";
      param.gift2 = "";
      param.gift3 = "";
      if (this.form.type.trim() !== "") {
        param[this.form.type] = "✔";
      }
      let date = new Date().getMonth() + 1 + "." + new Date().getDate();
      param.date = date;
      delete param.type;
      delete param._id;
      console.log(param, "新增");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/consumerList/addConsumer", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "添加成功");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "添加成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.btnLoading = false;
            this.search();
          }
          if (res.data.code == 302) {
            this.$message({
              duration: "2000",
              showClose: true,
              message: "重复手机号请检查输入信息！",
              type: "warning",
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 确认编辑
    onEdit() {
      this.btnLoading = true;
      let param = { ...this.form };
      param.gift1 = "";
      param.gift2 = "";
      param.gift3 = "";
      if (this.form.type.trim() !== "") {
        param[this.form.type] = "✔";
      }
      let date = new Date().getMonth() + 1 + "." + new Date().getDate();
      param.date = date;
      delete param.type;
      console.log(param, "编辑");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/consumerList/editInfo", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "修改数据");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "修改成功！",
              type: "success",
            });
            this.btnLoading = false;
            this.search();
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 修改每页选择数量
    changePageSize(val) {
      this.pages.pageNum = val;
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
.h5kmCenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .searchBox {
    display: flex;
    .iconBtn{
      padding-left: 10px;
      height: 54px;
      width: 46px;
      line-height: 54px;
      font-size: 30px;
      overflow: hidden;
      transition: all .5s ease-in-out 0s;
      i{
        transition: all .5s ease-in-out 0s;
      }
    }
    .beingClick{
      width: 90%;
    }
    .vanSearch{
      min-width: 60px;
      flex: 1;
      width:63px;
      transition: all .5s ease-in-out 0s;
    }
    .statusSpan {
      
    }
  }
  .cellBox {
    overflow-y: scroll;
    flex: 1;
    background: #d8e8f557;
    .cellDiv:nth-child(1) {
      border-top: 1px solid #d9dbdf;
    }
  }
  .cellDiv {
    border-bottom: 1px solid #79d2d2;
    padding: 7px 0;
    display: flex;
    span {
      height: 25px;
      line-height: 25px;
      display: inline-block;
      margin-left: 12px;
    }
    span:nth-child(1) {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 65px;
    }
    span:nth-child(2) {
      width: 105px;
      margin-left: 0px;
    }
    span:nth-child(3) {
      width: 50px;
    }
    span:nth-child(4) {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .vanTag{
      width:38px!important;
      text-align:center;
      margin-left:0;
    }
  }
  .cellDiv:hover {
    background-color: #e0e2eb;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 50px 10px;
    .block {
      display: flex;
      flex-direction: column;
      // height: 100%;
      width: 100%;
      background-color: #fff;
      .main {
        flex: 1;
        .wrapHeader {
          display: flex;
          .title {
            flex: 1;
            padding: 0 20px 0;
            text-align: center;
            font-weight: bold;
            line-height: 44px;
            font-size: 18px;
          }
        }
      }
    }
    .radioSingle {
      margin-bottom: 10px;
    }
  }
  .btnDiv {
    margin-top: 40px;
    display: flex;
    .btn {
      flex: 1;
    }
  }
  .noneData {
    height: 300px;
    line-height: 300px;
    width: 100%;
    text-align: center;
  }
}
</style>